<template>
  <div class="col-lg-12 control-section">
    <div>
      <ejs-grid
        ref="grid"
        id="grid"
        :locale="'it'"
        :dataSource="data"
        :allowPaging="true"
        :pageSettings="pageSettings"
        :editSettings="editSettings"
        :toolbar="toolbar"
      >
        <e-columns>
          <e-column
            field="OrderID"
            headerText="Order ID"
            width="100"
            textAlign="Right"
            :isPrimaryKey="true"
          ></e-column>
          <e-column
            field="CustomerID"
            headerText="Customer ID"
            width="100"
          ></e-column>
          <e-column
            field="Freight"
            headerText="Freight"
            type="number"
            width="180"
            format="C2"
            textAlign="Right"
            editType="numericedit"
          ></e-column>
          <e-column
            field="Phon"
            headerText="Number"
            type="number"
            width="100"
            :edit="ddParams"
            format="#######.###"
            editType="numericedit"
            textAlign="Right"
          ></e-column>
          <e-column
            field="EmployeeID"
            headerText="Employee ID"
            type="number"
            width="100"
            editType="numericedit"
          ></e-column>
          <e-column
            field="OrderDate"
            headerText="Order Date"
            width="130"
            type="date"
            editType="datetimepickeredit"
            :format="formatoptions"
            textAlign="Right"
          ></e-column>
          <e-column
            field="ShipCountry"
            headerText="Ship Country"
            width="150"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {
  GridPlugin,
  Edit,
  CommandColumn,
  Toolbar,
  Group,
  Sort,
  Page,
} from "@syncfusion/ej2-vue-grids";
import { data } from "./datasource.js";

Vue.use(GridPlugin);

// var index;
export default {
  data: function () {
    return {
      data: data,
      formatoptions: { type: "date", format: "dd/MM/yyyy" },
      pageSettings: { pageSize: 6 },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
      },
      toolbar: ["Add", "Edit", "Delete", "Update", "Cancel"],
      ddParams: {
        params: {
          useGrouping: false,
          validateDecimalOnType: true,
          decimals: 3,
          showSpinButton: false,
          format: "#######.###",
          created: function () {
            this.element.type = "tel";
          },
        },
      },
    };
  },
  methods: {},
  provide: {
    grid: [Edit, Toolbar, Group, CommandColumn, Sort, Page],
  },
};
</script>

<style>
@import "https://cdn.syncfusion.com/ej2/18.2.47/material.css";
</style>
